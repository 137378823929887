import React from 'react';
import './Faq.css';

const Faq = () => {
  return (
    <>
      <div className="Faq">
        <div className="container">
          <h1 className='fs-42 fw-800'>Need help?</h1>
          <p className="fs-24 fw-600 mb-5">Refer to these frequently asked questions!</p>
          <h3>Top Question</h3>

          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button px-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                How Do I Enter A Raffle?
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body px-0">
                  <ol typeof='number'>
                    <li>Create a MetaMask account here:<a href="https://metamask.io/" referrerPolicy="no-referrer">https://metamask.io/</a></li>
                    <li>Deposit ETH into your MetaMask Wallet.</li>
                    <li>Connect your metamask to our website.</li>
                    <li>Convert ETH into MROW Tokens.</li>
                    <li>Select the raffles you would like to join</li>
                    <li>Go to your cart, and check out!</li>
                  </ol>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button px-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Who Can Play?
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div class="accordion-body px-0">
                <p>Anyone! Though we encourage people who play to join our community, the raffle is open to everyone!</p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button px-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                How is the winner selected?
                </button>
              </h2>
              <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                <div class="accordion-body px-0">
                  <p className='mb-4'>There will be two types of draws. Live and Instant draws.</p>
                  <ol typeof='number'>
                    <li>Live draws will be available and announced on our Facebook page</li>
                    <li>Instant draws will be drawn using randomization software which performs the draw independently.</li>
                  </ol>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFour">
                <button class="accordion-button px-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                When Is The Draw?
                </button>
              </h2>
              <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                <div class="accordion-body px-0">
                  <p className='mb-4'>We hold live drawings on Facebook depending on when a raffle fills up! When a big prize is ready to draw, we will schedule the draw for the next business day. For instant draws, once a prize is ready to draw it will automatically be chosen within the hour!</p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFive">
                <button class="accordion-button px-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                How Many Tickets Can I Buy For A Raffle?
                </button>
              </h2>
              <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                <div class="accordion-body px-0">
                  <p className='mb-4'>As many as you want! For now, there is no limit to the amount of tickets a single person can purchase.</p>
                 
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingSix">
                <button class="accordion-button px-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                Can I Get My Tickets Refunded?
                </button>
              </h2>
              <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                <div class="accordion-body px-0">
                  <p className='mb-4'>Unfortunately we aren’t able to remove or refund tickets purchased for a raffle, but MROW tokens can be exchanged back into ETH.</p>
                  
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingSeven">
                <button class="accordion-button px-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                How Much Does It Cost To Enter A Raffle?
                </button>
              </h2>
              <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                <div class="accordion-body px-0">
                  <p className='mb-4'>It can cost as little as 1 Token and as much as 10 Tokens.</p>
                  
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingEight">
                <button class="accordion-button px-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                How Do I Know If I Won?
                </button>
              </h2>
              <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                <div class="accordion-body px-0">
                  <p className='mb-4'>Once you set up an account, we will be able to email and call you to congratulate you on your win. You will also be able to see the winners in our winners list.</p>
                  
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingNine">
                <button class="accordion-button px-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                Where Can I View My Tickets? 
                </button>
              </h2>
              <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                <div class="accordion-body px-0">
                  <p className='mb-4'>When you log in, you can view your tickets entered in the My Raffles section at the top of the website. </p>
                 
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTen">
                <button class="accordion-button px-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                How Do Prizes Get Delivered?
                </button>
              </h2>
              <div id="collapseTen" class="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                <div class="accordion-body px-0">
                  <p className='mb-4'>Prizes will be delivered the best way possible depending on your location. Cash prizes will be sent via Money Transfer all around the globe! Prizes that are limited in area and cannot be delivered will be given a cash alternative.</p>
                  
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingEleven">
                <button class="accordion-button px-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                Can I Receive A Cash Alternative To My Prize?
                </button>
              </h2>
              <div id="collapseEleven" class="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
                <div class="accordion-body px-0">
                  <p className='mb-4'>Upon request, an exchange of Tokens may be given in lieu of the prize won. Tokens can then be exchanged for ETH to be withdrawn upon your desire.</p>
                  
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwelve">
                <button class="accordion-button px-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                Can I Suggest A Prize?
                </button>
              </h2>
              <div id="collapseTwelve" class="accordion-collapse collapse" aria-labelledby="headingTwelve" data-bs-parent="#accordionExample">
                <div class="accordion-body px-0">
                  <p className='mb-4'>Of course! Send in your suggestions to <a href="mailto:Info@millionairerowraffle.com" referrerPolicy="no-referrer">Info@millionairerowraffle.com</a>.</p>
                  
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThirteen">
                <button class="accordion-button px-0 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                What If A Prize Isnt Claimed?
                </button>
              </h2>
              <div id="collapseThirteen" class="accordion-collapse collapse" aria-labelledby="headingThirteen" data-bs-parent="#accordionExample">
                <div class="accordion-body px-0">
                  <p className='mb-4'>We will call and email as much as we can to try and reach the winner, but in the event we cannot reach a winner after 180 days, we reserve the right to distribute the prize to our charity partners.</p>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Faq
