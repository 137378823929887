import React, { useState } from 'react';
import { NavLink, Link } from "react-router-dom";
import './Header.css';
import Logo from '../../assets/logo.png';
import Web3 from 'web3';
import { generateAndGetUser, logoutUser, submitSignature } from '../../api/user';
import { useDispatch, useSelector } from 'react-redux';

let web3 = undefined;

const Header = () => {
  const [navShow, setNavShow] = useState(false);
  const userStore = useSelector(state => state.userStore);
  const dispatch = useDispatch()

  const handleSignMessage = async ({
		publicAddress,
		nonce,
	}) => {
		try {
      console.log(publicAddress, nonce)
			const signature = await web3?.eth.personal.sign(
				`I am signing my one-time nonce: ${nonce}`,
				publicAddress,
				'' // MetaMask will ignore the password argument here
			);

			return { publicAddress, signature };
		} catch (err) {
      console.log(err)
			throw new Error(
				'You need to sign the message to be able to log in.'
			);
		}
	};

  const handleConnection = async (e) => {
    e.preventDefault()
    console.log("handling wallet connection")
    if (!(window?.ethereum)) {
			window.alert('Please install MetaMask first.');
			return;
		}

    if(!web3){
      try{
        await window?.ethereum.enable()
        web3 = new Web3(window?.ethereum)
      } catch (e) {
        window.alert('You need to allow MetaMask.');
				return;
      }
    }

    const eth = await web3.eth.getAccounts()
    console.log(eth)
    const publicAddress = eth[0].toLowerCase();

    const userInfo = await generateAndGetUser({publicAddress: publicAddress})
    if(userInfo){
      const user = userInfo.user
      const signPayload = await handleSignMessage({publicAddress: user.publicAddress, nonce: user.nonce})
      const userLoginInfo = await submitSignature(signPayload)
      dispatch({
        type: 'SAVE_USER',
        payload: userLoginInfo
      })
    }
  }

  const handleLogout = async (e) => {
    e.preventDefault()
    const res = await logoutUser(userStore.token)
    console.log('res was :', res)
    const payload = {
        user: {
          id: null,
          name: null,
          email: null,
          publicAddress: null,
          dateOfBirth: null,
          nonce: null,
          gender: null
      },
      token: null
    }
    dispatch({
      type: 'SAVE_USER',
      payload: payload
    })
  }
  return (
    <>

      {/* large screen */}
      <div className="Nav d-none d-lg-flex">
        <nav className="navbar navbar-expand-md navbar-dark fixed-top">
          <div className="container">
            <Link className="navbar-brand" to="/"><img src={Logo} alt="" /></Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <ul className="navbar-nav align-items-center ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a href='#' className="btn nav-link animated-button victoria-two">Convert</a>
              </li>
              {
                userStore.token ?
                <li className="nav-item">
                  <a className="btn nav-link animated-button victoria-two">{userStore.user.publicAddress.slice(0, 17)}...</a>
                </li>:
                <li className="nav-item">
                  <a href='#' onClick={handleConnection} className="btn nav-link animated-button victoria-two">Connect Wallet</a>
                </li>
              }
              <li className="nav-item withJoin">
                <NavLink to="/cart" className="btn nav-link">
                  <span className="iconify" data-icon="ant-design:shopping-cart-outlined"></span>
                  <div className="cartNotification">1</div>
                </NavLink>
              </li>
              <li className="nav-item">
                <a className="btn nav-link" onClick={() => setNavShow(true)}><span className="iconify textWarn" data-icon="bx:user-circle"></span></a>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      {/*  small screen */}

      <div className="Nav d-flex d-lg-none">
        <nav className="navbar navbar-dark fixed-top">
          <div className="container">
            <ul className="navbar-nav justify-content-between flex-row align-items-center">
              <li className="nav-item d-none d-lg-block">
                <NavLink className="btn nav-link animated-button victoria-two " to="/" >Convert</NavLink >
              </li>
              <li className="nav-item d-none d-lg-block">
                <a href='#' onClick={handleConnection} className="btn nav-link animated-button victoria-two">Connect Wallet</a>
              </li>
              <li className="nav-item borderHover withJoin">
                <NavLink className="nav-link" to="/cart" >

                  <span className="iconify " data-icon="ant-design:shopping-cart-outlined"></span>
                  <div className="cartNotification">1</div>

                </NavLink>
              </li>
              <li className="nav-item d-block d-lg-none">
                <Link className="navbar-brand" to="/"><img src={Logo} height={30} alt="" /></Link>
              </li>
              <li className="nav-item">
                <a className="btn nav-link" onClick={() => setNavShow(true)}><span className="iconify textWarn" data-icon="bx:user-circle"></span></a>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      {navShow ? <div className="sideNav">
        <div className="navContent">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className='userName'>
              Welcome back <span className="text-capitalize">john</span>
            </div>
            <div className='pointer' onClick={() => setNavShow(false)}>
              <span className="iconify" data-icon="icomoon-free:cross"></span>
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-6 ps-0 pe-2 mb-3">
              <Link to="/raffles" className='text-white'>
                <div className="navCard">
                  <span className="iconify" data-icon="fluent:ticket-diagonal-16-regular"></span>
                  <div className="cardTitle">My raffles</div>
                </div>
              </Link>
            </div>
            <div className="col-6 ps-2 pe-0 mb-3">
              <a href="#" className='text-white'>
                <div className="navCard">
                  <span className="iconify" data-icon="bx:coin"></span>
                  <div className="cardTitle">My Tokens</div>
                  <div className="credits">$ 0.00</div>
                </div>
              </a>
            </div>
            <div className="col-6 ps-0 pe-2 mb-3">
              <Link to="/winners" className='text-white'>
                <div className="navCard">
                  <span className="iconify" data-icon="akar-icons:trophy"></span>
                  <div className="cardTitle">Winners</div>
                </div>
              </Link>
            </div>
            <div className="col-6 ps-2 pe-0 mb-3">
              <a href="#" className='text-white'>
                <div className="navCard">
                  <span className="iconify" data-icon="clarity:video-camera-line"></span>
                  <div className="cardTitle">Live draws</div>
                </div>
              </a>
            </div>
            <div className="col-6 ps-0 pe-2 mb-3">
              <Link to="/charities" className='text-white'>
                <div className="navCard">
                  <span className="iconify" data-icon="entypo:awareness-ribbon"></span>
                  <div className="cardTitle">Charity</div>
                </div>
              </Link>
            </div>
            <div className="col-6 ps-2 pe-0 mb-3 d-none">
              <a href="#" className='text-white'>
                <div className="navCard">
                  <div className="toggleSwitch">
                    <label className="switch">
                      <input type="checkbox" checked />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="cardTitle">Toggle theme</div>
                </div>
              </a>
            </div>
            {
              userStore.token &&
              <div className="col-6 ps-2 pe-0 mb-3">
                <a href='#' onClick={handleLogout}>
                  <div className="navCard">
                    <span className="iconify" data-icon="fe:logout"></span>
                    <div className="cardTitle">Log out</div>
                  </div>
                </a>
              </div>
            }

          </div>
          <h6 className='fs-16 my-4'>Account</h6>
          <div className="row mx-0">
            <div className="col-6 ps-0 pe-2">
              <Link to="/personal-info" className='text-white'>
                <div className="navCard">
                  <span className="iconify" data-icon="fa-regular:user"></span>
                  <div className="cardTitle">Personal info</div>
                </div>
              </Link>
            </div>
          </div>
          <h6 className='fs-16 my-4 d-none'>Site information</h6>
          <div className="row mx-0 my-4">
            <div className="col-6 ps-0 mb-3 d-none">
              <a href="#" className='text-white'>
                <div className="navCard">
                  <span className="iconify" data-icon="bx:help-circle"></span>
                  <div className="cardTitle">Help & FAQs</div>
                </div>
              </a>
            </div>
            <div className="col-6 ps-0">
              <a href="#">info@millionairerowraffle.com</a>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <div><Link to="/terms">Terms</Link></div>
            <div className="mx-1">|</div>
            <div><Link to="/faq">Help</Link></div>

          </div>

          <div className="social-icons">
            <ul>
              <li>
                <a href="https://www.facebook.com/MillionaireRowRaffle/" target="_blank" rel="noopener noreferrer">
                  <span className="iconify icon" data-icon="dashicons:facebook-alt"></span>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/RaffleRow" target="_blank" rel="noopener noreferrer">
                  <span className="iconify icon" data-icon="akar-icons:twitter-fill"></span>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/millionairerowraffle/" target="_blank" rel="noopener noreferrer">
                  <span className="iconify icon" data-icon="akar-icons:instagram-fill"></span>
                </a>
              </li>
              <li>
                <a href="https://discord.gg/Qd8KUPbrJT" target="_blank" rel="noopener noreferrer">
                  <span className="iconify icon" data-icon="akar-icons:discord-fill"></span>
                </a>
              </li>
            </ul>
          </div>

        </div>
      </div>
        :
        <>
        </>
      }
    </>
  )
}

export default Header