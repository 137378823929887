import React from 'react';
import './Terms.css';

const Terms = () => {
    return (
        <>
            <div className="Terms">
                <div className="container">
                    <h1 className='fw-600 mb-4'>Terms & Conditions</h1>

                    <p>The following Terms apply to all purchases of Millionaire Row Raffle Tickets and all Entries submitted to any Competition on the Promoter's Website or App.</p>

                    <h3>TERMS</h3>

                    <p>When joining Millionaire Row Raffle, Entrants will be required to tick on the Website or App that they have read and accepted these Terms and agreed to be bound by them.</p>

                    <h3>The Promoter</h3>
                    <ol>
                        <li>All Competitions are operated by the Promoter, and the Promoter is the official sponsor of each Competition unless specified otherwise. Where the product is sourced from a Millionaire Row Raffle Partner, the Promoter is authorised by that Millionaire Row Raffle Partner to offer their respective Product(s) as Prizes in the Competitions.</li>
                    </ol>
                    <h3>How to enter</h3>
                    <ol start={2}>
                        <li>By submitting an Entry, a Postal Entry or a Telephone Entry, Entrants agree to be bound by these Terms. If you do not so agree to be bound, do not purchase Millionaire Row Raffle Tickets, and do not submit an Entry, Postal Entry or Telephone Entry.</li>
                        <li>
                        Purchasers of Millionaire Row Raffle Tickets will be asked to provide their contact details, including an e-mail address and, optionally, a telephone number. This information will be processed in accordance with the provisions below and the Promoter’s Data Protection and Privacy Policy.
                        </li>
                        <li>The Promoter will store and process the Entrant’s personal information. The personal information must include details of at least one form of contact and will be used for the following purposes: </li>
                        <ol type='a'>
                            <li>
                            to notify any Winner or Runner(s) Up that they have won a Prize in a Competition;
                            </li>
                            <li>
                            to administer the Website and App and conduct the Competitions; and
                            </li>
                            <li>
                            after a Draw, to post the Winner and, where applicable, any Runner(s) Up’s first name, town/county of residence and profile image or other provided photo on the Website and/or App, and in media communications about the Competitions.
                            </li>
                        </ol>
                        <li>
                        All Entrants are solely and completely responsible for providing the Promoter with accurate and current contact details.
                        </li>
                        <li>
                        The Promoter will be in no way liable for any failure or inability to make contact with any Entrant due to any errors, omissions or inaccuracies in the contact details provided by the Entrants or otherwise.
                        </li>
                        <li>
                        The Promoter will not accept: 
                        </li>
                        <ol type='a'>
                            <li>responsibility for Entries that are lost, mislaid, damaged or delayed in transit, regardless of cause including, for example, equipment failure, technical malfunction, systems, satellite, network, server, computer hardware or software failure of any kind; or</li>
                            <li>any other purported proof of entry to the Competition other than as recorded by the systems of the Website or App, or by the Promoter upon receipt of a Postal Entry or Telephone Entry.</li>
                        </ol>
                        <li>
                        An Entry shall be declared void (without any refund being given) if the Entrant engages in: 
                        </li>
                        <ol type='a'>
                            <li>any form of fraud, whether actual or apparent;</li>
                            <li>fraudulent misrepresentation;</li>
                            <li>fraudulent concealment;</li>
                            <li>hacking or interference with the proper functioning of the Website; or</li>
                            <li>amending, or unauthorised use of, any code that constitutes the Website</li>
                        </ol>
                        <li>
                        Each individual Entrant may submit up to the maximum number of entries specified in total for any one particular Competition.
                        </li>
                        <li>
                        Only valid Entries will be entitled to participate in a Competition and be eligible to win a Prize in a Competition.
                        </li>
                        <li>
                        	All funds paid to the Promoter in return for Millionaire Row Raffle Tickets, and all Entries to any one Competition are final. Refunds shall not be given at any time apart from under the circumstances described in terms 28 and 40.
                        </li>
                        
                        <li>
                        	For further help with Entries please contact us by using the chat icon on the Website.
                        </li>
                    </ol>
                    <h3>Eligibility</h3>
                    <ol start={13}>
                        <li>Competitions are open for entry only to private individuals (i.e. not a limited company, partnership or limited liability partnership)</li>
                        <li>Only Registered Users are eligible to enter any Competition.</li>
                        <li>In entering a Competition, all Entrants confirm that they are eligible to do so and eligible to claim any Prize awarded in the relevant Competition. The Promoter may require any Entrant, including any Winner and/or Runner(s) Up to provide evidence that they are and were eligible to enter a particular Competition.</li>
                        <li>The Promoter will not accept and will not refund Entries that are: </li>
                        <ol type='a'>
                            <li>automatically generated by computer;</li>
                            <li>completed by third parties;</li>
                            <li>altered, reconstructed, forged or tampered with; or</li>
                            <li>incomplete.</li>
                        </ol>
                        <li>The Promoter reserves all rights to disqualify Entrants if their conduct is contrary to the spirit or intention of the Competition entered.</li>
                        <li>Entries on behalf of another private individual will not be accepted, and joint submissions are not allowed.</li>
                        <li>By entering the Competition, Entrants warrant that all information that they submit is accurate, true, current and complete. The Promoter reserves the right to disqualify any Entrant (entirely at the Promoter's discretion) if there are reasonable grounds to believe the Entrant has acted in breach of any of these Terms.</li>
                        <li>The Promoter reserves the right to close or suspend a Registered User’s Account (and prevent the individual who created the Account from participating in any future competitions under a different e-mail) at any time and for any reason. Without limiting the preceding sentence, the Promoter shall be entitled to close or suspend a Registered User’s account if:</li>
                        <ol type='a'>
                            <li>the Registered User is writing abusive, misleading, or factually incorrect statements to the Promoter's staff, on social media, review websites, and so on;</li>
                            <li>the Promoter considers that the Registered User has used the Website in a fraudulent manner or for illegal and/or unlawful or improper purposes;</li>
                            <li>the Promoter considers that the Registered User has used the Website in an unfair manner, has deliberately cheated or taken unfair advantage of the Promoter or any of its other Registered Users or if the Registered User’s Account is being used for the benefit of a third party;</li>
                            <li>the Promoter is requested to do so by the police, any regulatory authority or court;</li>
                            <li>the Promoter considers that any of the events referred to in ‘a.’ to ‘d.’ may have occurred or are likely to occur; or</li>
                            <li>the Registered User’s Account is deemed to be dormant. </li>
                        </ol>
                        <li>If the Promoter closes or suspends the Registered User’s Account for any of the reasons referred to in ‘a.’ to ‘f.’ above, the Registered User shall be liable for any and all claims, losses, liabilities, damages, costs and expenses incurred or suffered by the Promoter arising therefrom and shall indemnify and hold the Promoter harmless on demand for the same. In the circumstances referred to in ‘a.’, ‘b.’, ‘f.’ and ‘g’ above, the Promoter may choose to refund any outstanding entries to the Registered User. In the same circumstances in the preceding sentence, the Promoter reserves the right to disqualify any Entries that have already been entered into a Competition for which the Draw has not yet taken place.</li>
                    </ol>
                    <h3>Prizes and the Draw</h3>
                    <ol start={22}>
                        <li>The Winner and any Runner(s) Up for each Competition will be determined and awarded the relevant Competition Prize(s) so specified on the Website in accordance with these Terms.</li>
                        <li>The Draw to determine the Winner and, where applicable, any Runner(s) Up will be made on the Draw Date. </li>
                        <li>Unless brought about by circumstances outside of the Promoter's control (e.g. a server crash that lasts for a notable period), the Promoter will never extend the Closing Date for any Competition.</li>
                        <li>On a Competition's Draw Date: </li>
                        <ol type='a'>
                            <li>there will be a Draw to determine the Winner, and the Prize shall be awarded to the Winner subject to KYC checks being completed as outlined in term 35;</li>
                            <li>where the Competition provides for one or more Runner Up Prize(s), there will be a Draw or Draws to determine the Runner(s) up, who shall be awarded the associated Runner Up Prize(s) subject to KYC checks being completed as outlined in term 35.</li>
                        </ol>
                        <li>If any Winner or Runner(s) Up cannot be contacted by the Promoter within 21 (twenty-one) days of being notified of their status as the Winner by e-mail and/or telephone provided in the contact details submitted in their Entry, the Promoter shall be entitled to award their Prize to the Entrant next Drawn, with the effect that the first Runner Up (as applicable) would become the Winner, the second Runner Up (as applicable) would become the first Runner Up, and so on. Any alternate Winner or Runner Up shall likewise comply with the above 21 (twenty-one) day contact requirement. </li>
                        <li>The Promoter shall use its best efforts to ensure and preserve the random nature of the Draw. In the event that any mechanical defect occurs that relates to the draw (such as an incomplete number being drawn), the draw will be cancelled and re-run once the defect has been remedied, or, failing this, using an alternative random draw method. The result(s) of the Draw are final and the Promoter shall not enter into any correspondence in relation to the same.</li>
                        <li>Upon the request of the Promoter, the Winner, and where applicable, any Runner(s) Up will be required to forward personal identification, such as a driver's license or passport, as well as a recent utility bill or other official document confirming their address, to the Promoter to prove their identity and age, that their Entry, Postal Entry or Telephone Entry was made in accordance with these Terms, and that there is no lawful impediment to the Winner or any Runner(s) Up being awarded any Prize in the relevant Competition. The Promoter may refuse delivery of the Prize to the Winner and any Runner(s) Up if the requested personal identification is not received. In the event that the Promoter reasonably believes that there may be a lawful impediment to awarding a Prize to a Winner or any Runner(s) Up, the Promoter may suspend making such award until the legal issue is resolved or if, in the reasonable opinion of the Promoter such issue cannot be resolved, to award that Prize in a like manner as set out in term 32.</li>
                        <li>In the event that the Winner or Runner Up wins a vehicle, unless the Promoter elects at its sole discretion to deliver the vehicle, the Winner or Runner Up will be required to travel to the Promoter's offices or a mutually agreed location to collect the Prize. For winners outside of Great Britain, registration and transport will be the responsibility of the Winner or Runner Up.</li>
                        <li>The Promoter is entitled to refuse delivery of a Prize to a P.O. box or any other location that may be used to conceal the identity of a Winner, in order to ensure that a Prize is not delivered to a fraudulent Winner.</li>
                        <li>In cases where the Prize is an experience, which should be taken to include, for example, domestic and international holidays, day-trips and one-off events or occasions, the Winner of the Prize or any Runner(s) Up will be contacted by either the Promoter or a Millionaire Row Raffle Partner to arrange delivery of the experience. If the Winner or Runner Up fails to confirm necessary details (e.g. the people who will be attending the experience, the desired date of the experience, etc.) within 14 days from the point of being contacted, then it is acknowledged that, on occasion, certain elements of the Prize may not be available, in which case the Promoter and the Winner/Runner Up will arrange a mutually agreeable substitute experience up to the same value as the original Prize. Where booking with a third-party provider, the Winner or Runner Up must get in contact with the Promoter before booking to confirm all items of the experience (e.g. flights, travel dates, number of people) so that the Promoter can ensure full delivery of the Prize on the desired dates, as often these items are booked with different providers and require co-ordination. In instances where the Winner or Runner up fails to confirm the details with the Promoter before booking the experience and complications arise, the Promoter will substitute up to the total value of the original Prize where possible, but will have no further liability to the Winner or Runner Up.</li>
                        <li>The Promoter does not in any way guarantee or give any warranties as to the value of the Prize, its condition, history or any other matters associated with the Prize.</li>
                        <li>The Promoter reserves the right to remove a Competition from the Website or App at any time, and in the rare event that this occurs, will refund all the Entries to that Competition to the Registered User in the form of Site Credit.</li>
                    </ol>
                    <h3>Charity</h3>
                    <ol start={34}>
                        <li>The Millionaire Row Raffle Partner or the Promoter may, for any Competition for which it has provided a Prize, specify a Charity to which a percentage of the total Millionaire Row Raffle Ticket sales will be donated. The Promoter or the Millionaire Row Raffle Partner will make the Charitable Donation after the Draw.</li>
                    </ol>
                    <h3>Announcement of Winners and any Runner(s) Up</h3>
                    <ol start={35}>
                        <li>The Promoter has the right, with the winner's consent, to announce and publish any of the information outlined in term 10(c) pertaining to any Competition’s Winner and Runner(s) Up on the Website and via email marketing campaigns, as well as on the Promoter’s associated social media pages/sites, such as Facebook, Instagram and Twitter. The Promoter may also ask the Winner and/or any Runner(s) Up to participate in further reasonable publicity requests.</li>
                    </ol>
                    <h3>Limitation of Liability</h3>
                    <ol start={36}>
                        <li>Insofar as is permitted by law, the Promoter, its agents or distributors will not in any circumstances be responsible or liable to compensate any Entrant or accept any liability for any loss, damage, personal injury or death occurring as a result of submitting an Entry, Postal Entry or Telephone Entry, or in relation to the use and enjoyment of a Prize by the Winner or Runner(s) Up gained through any Competition, except where death or personal injury is caused by the negligence or fraud of the Promoter, or that of their agents, distributors, or employees. The Winner’s statutory rights are not affected. </li>
                        <li>The liability of the Promoter to each Entrant is limited to the aggregate value of the Entry Fees paid by that Entrant. </li>
                        <li>The Promoter accepts no liability for errors or omissions contained within the description of any Prize awarded as part of any of the Competitions on the Website or App, or the Prize Value, or any other description or specification or any other part of the Website or App. It is the responsibility of each Entrant (and in particular the Winner and any Runner(s) Up) to satisfy him/herself as to the accuracy of any such details and/or any content of the Website or App. </li>
                        <li>The Promoter accepts no liability for errors or omissions caused by human error or technical faults within the Promoter's technological infrastructure. </li>
                        <li>The Promoter accepts no liability for non-receipt or issues with receipt of a Prize due to errors in information provided by the Winner or Runner Up, such as incorrect bank account details or a wrong delivery address. </li>
                    </ol>
                    <h3>General</h3>
                    <ol start={41}>
                        <li>The Promoter shall not be a trustee of any Entry Fees received, unless it is subject to a specific contractual agreement with a Millionaire Row Raffle Partner or Charity to act as such.</li>
                        <li>The Promoter reserves the right to suspend or cancel any Competition at any time, either before or after Entries have been received. If a Competition is cancelled due to the Promoter’s actions and specifically not those of the Millionaire Row Raffle Partner or any other entity associated with the Competition, the Promoter will return the Entry Fees paid by each Entrant to them as Site Credit, and return the relevant Entry Fee made by each Entrant to them as Site Credit (relative to the value of the ticket price in the Competition). Where all the Entry Fees have been returned, the Promoter shall have no further liability to the Entrant or to any other person. </li>
                        <li>Without prejudice to the operation of term 50, the Promoter reserves the right to cancel any Competition in the event that an order is made or a resolution is passed for the winding up of the Promoter, or an order is made for the appointment of an administrator to manage the affairs of the Promoter, or circumstances arise which entitle a court or creditor to appoint a receiver or manager or which entitle a court to make a winding up order, or the Promoter takes or suffers any analogous action in consequence of debt or an application to court for protection from its creditors is made by the Promoter. </li>
                        <li>The Promoter reserves the right to make reasonable amendments to these Terms at any time, with immediate effect upon publishing such amendments on the Website. Any such amendments shall not prejudice any Entries received prior to the time of such changes. </li>
                        <li>These Terms shall not create or be construed as creating any form of contract, joint venture or other agreement between any Entrant and the Promoter.</li>
                        <li>All Competitions that the Promoter promotes, their administration and all associated activities shall be governed by the law, and all parties submit to the exclusive jurisdiction of the courts.</li>
                    </ol>
                    <h3>Definitions</h3>
                    <p>Note that throughout the Terms, plural forms of the capitalised terms are used, and have the same meaning as given below.</p>
                    <p><b>Account:</b> means the Registered User's Millionaire Row Raffle account.</p>
                    <p><b>Charity/Charities:</b>means the charities or charitable causes that may be specified by a Millionaire Row Raffle Partner or the Promoter for a particular Competition. </p>
                    <p><b>Charitable Donation: </b>means the percentage of the total Millionaire Row Raffle Ticket sales for a Competition that will be paid to the Charity or Charities specified by the Millionaire Row Raffle Partner.</p>
                    <p><b>Closing Date:</b> means the date on which a Competition closes</p>
                    <p><b>Competition:</b> means any free draw operated by the Promoter on its Website which these Terms apply, wherein the Entrants submit Entries via the Website for a chance to win Prize(s).</p>
                    <p><b>Draw:</b> means the random selection of an Entry from the class of total entries, that occurs on the relevant Competition’s Draw Date to determine the Winner and, where applicable, any Runner(s) Up.</p>
                    <p><b>Draw Date:</b> means the date at which the Draw takes place, which will be the earlier of the Closing Date of the relevant Competition, or the point that the Competition is sold out.</p>
                    <p><b>Entrant:</b> means any natural person (not including a limited company, partnership or limited liability partnership) who validly submits an Entry, Postal Entry or Telephone Entry to a Competition, in accordance with these Terms. </p>
                    <p><b>Entry:</b> means a validly submitted and completed entry by the Entrant through the Website in order to gain an opportunity to win a Prize. </p>
                    <p><b>Entry Fee:</b> means the entry fee payable as a condition of submitting a valid Entry. The fee will be stated clearly for each Competition, and may vary between Competitions.</p>
                    <p><b>Flash Competition:</b> means a competition for a Prize that has a duration of 48 hours or less, and can be entered either online or for free (see terms 8 and 9 for more information) </p>
                    <p><b>Membership Form:</b> means the form required to be completed, unless a linked social media sign-in is used, in order for a Visitor to become a Registered User.</p>
                    <p><b>Partnered Charity:</b> means one of the charities that can be selected to be sponsored by Registered Users, to receive donations in accordance with the procedure laid out in term 41.</p>
                    <p><b>Postal Entry:</b> means a validly composed and posted postcard or letter, in accordance with these Terms, that is received by the Promoter. </p>
                    <p><b>Prize: </b>refers to the good or service so specified in each Competition that a Winner or Runner Up of that Competition may receive. The Prize to be awarded to the Winner and/or Runner(s) Up cannot be substituted for any Prize of equivalent value, such as a cash prize, solely due to a request of the Winner and/or Runner(s) Up or the location of the Winner and/or Runner(s) Up are outside of the delivery area. The Promoter reserves the right to offer a cash alternative to the Prize specified in the Competition, and to offer a Prize of equal or greater value if for some reason the Prize is no longer available due to circumstances outside the Entrant’s control.</p>
                    <p><b>Prize Value: </b>means the description of the value of a Prize of a Competition.</p>
                    <p><b>Product: </b>means a good or service owned by a Millionaire Row Raffle Partner. </p>
                    <p><b>Promoter: </b>means Millionaire Row Raffle.</p>
                    <p><b>Millionaire Row Raffle Partner: </b>refers to a company or private individual that enters into an agreement with the Promoter to provide one or more Products.</p>
                    <p><b>Millionaire Row Raffle Tickets: </b>means the virtual tickets that Registered Users purchase in order to enter into a Competition.</p>
                    <p><b>Registered User: </b>means a Visitor that has filled out a Membership Form, or signed in through one of the linked social media platforms such as Facebook or Twitter. Registered Users are eligible to make Entries to any Competition, insofar as they are eligible to do so due to the nature of the Competition itself. </p>
                    <p><b>Runner(s) Up: </b>means the Entrant(s) selected second, third, fourth, and so on as required by the Draw on the Draw Date.</p>
                    <p><b>Runner Up Prize(s): </b>means the Prize that a Runner Up will receive, as may be specified in a Competition.</p>
                    <p><b>Site Credit: </b>means a store of credit that can be applied to reduce a Registered User’s checkout balance.</p>
                    <p><b>Terms: </b>means these terms and conditions.</p>
                    <p><b>Visitor: </b>means any unique visitor that is a natural person (not including a limited company, partnership or limited liability partnership) to the Millionaire Row Raffle Website or App.</p>
                    <p><b>Website: </b>means <a href='https://MillionaireRowRaffle.com' referrerPolicy='no-referer'>https://MillionaireRowRaffle.com</a> and all sub-domains attached to this domain.</p>
                    <p><b>Winner: </b>means the Entrant first selected by the Draw on the Draw Date, and in accordance with these Terms.</p>

                </div>
            </div>
        </>
    )
}

export default Terms
