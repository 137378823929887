/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './Home.css';
import applePay from '../../assets/applepay-paypal.svg';
import tp from '../../assets/tp.svg';
import banner from '../../assets/mainBanner.png';
import bannerMob from '../../assets/mainBanner.png';
import mega from '../../assets/mega-jackpotv2.svg'
import RaffleCard from '../RaffleCard/RaffleCard';
import { Link } from 'react-router-dom';
import img from '../../assets/1000-X2.png';
import img1 from '../../assets/2000-X2.png';
import img2 from '../../assets/300- X2.png';
import img3 from '../../assets/5000-X2.png';
import img4 from '../../assets/8000-X2.png';



const Home = () => {

  let cardImgs = [
    {img: img, text: 1000}, {img: img1, text: 2000}, {img: img2, text: 3000}, {img: img3, text: 5000}, {img: img4, text: 8000}
  ]


  const card = []

  for (let i = 0; i < 5; i++) {
    card.push(
      <div className="col-lg-3 mb-3">
        <Link to="raffle-detail">
          <RaffleCard cardImgs={cardImgs[i]} />
        </Link>
      </div>
    )
  }



  return (
    <>
      <div className="Home">
        <div className="topBanner d-none">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <img src={applePay} className='applePay' alt="" />
              </div>
              <div>
                <img src={tp} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="bannerImg">
          <img src={banner} className="w-100 d-none d-lg-block" alt="" />
          <img src={bannerMob} className="w-100 d-block d-lg-none" alt="" />
        </div>

        <div className="container">
          <Link to="raffle-detail">
            <div className="cardWithText">
              <div className="row mx-0 w-100">
                <div className="col-md-7 order-lg-first order-last">
                  <div className='megaCrown mb-3'><img src={mega} alt="" /></div>
                  <h3>$8,000 Tax Free Cash</h3>
                  <h5 className='price'>5 Tokens</h5>

                  <div class="progress">
                    <div class="progress-bar w-75" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>

                  <p className='ticketSold'>2132 tickets sold</p>

                  <a href="#" className='btn btnWarn animated-button victoria-two'>Enter Now</a>
                </div>
                {/* <div className="col-md-5 order-lg-last order-first">
                  <div className="d-flex justify-content-end">
                    <div className="timer pulse">
                      <div className="timeHead">DRAW IN</div>
                      <div className="timeBody">
                        <div className="row mx-0 px-2">
                          <div className="col">0-</div>
                          <div className="col">:</div>
                          <div className="col">0-</div>
                          <div className="col">:</div>
                          <div className="col">0-</div>
                        </div>
                        <div className="row mx-0">
                          <div className="col">18</div>
                          <div className="col">43</div>
                          <div className="col">42</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>

            </div>
          </Link>
          <Link to="raffle-detail">
            <div className="cardWithText bannerchange">
              <div className="row mx-0 w-100">
                <div className="col-md-7 order-lg-first order-last">
                  <div className='megaCrown mb-3'><img src={mega} alt="" /></div>
                  <h3>VENETIAN DREAM HOLIDAY</h3>
                  <h5 className='price'>2 Token</h5>

                  <div class="progress">
                    <div class="progress-bar w-75" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>

                  <p className='ticketSold'>164 tickets sold</p>

                  <a href="#" className='btn btnWarn animated-button victoria-two'>Enter Now</a>
                </div>
                {/* <div className="col-md-5 order-lg-last order-first">
                  <div className="d-flex justify-content-end">
                    <div className="timeBadge">
                      <div class="iconify" data-icon="akar-icons:clock"></div>
                      <div className='left'>5 DAYS LEFT</div>
                    </div>
                  </div>
                </div> */}
              </div>

            </div>
          </Link>
        </div>

        <section>
          <div className="container">
            <div className="row mx-0">
              <div className="col-lg-4 ps-lg-0 mb-3 mb-lg-0">
                <div className="statCard">
                  <h2>$0</h2>
                  <h6>won in prizes</h6>
                </div>
              </div>
              <div className="col-lg-4 mb-3 mb-lg-0">
                <div className="statCard">
                  <h2>$0</h2>
                  <h6>raised for charity</h6>
                </div>
              </div>
              <div className="col-lg-4 pe-lg-0">
                <div className="statCard">
                  <h2>0</h2>
                  <h6>raffle winners</h6>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='how-works d-none d-lg-block'>
          <div className="container">
            <div className="row mx-0 justify-content-center">
              <div className="col-lg-10">
                <div className="worksCard">
                  <h2 className='fs-28'>How Millionaire Row Raffle Works</h2>
                  <div className="row mx-0 my-3">
                    <div className="col-lg-3">
                      <div className="digitCircle">1</div>
                      <p>Connect MetaMask Wallet</p>
                    </div>
                    <div className="col-lg-3">
                      <div className="digitCircle">2</div>
                      <p>Transfer ETH Into MROW Tokens</p>
                    </div>
                    <div className="col-lg-3">
                      <div className="digitCircle">3</div>
                      <p>All Tickets Are Entered Into The Random Draw</p>
                    </div>
                    <div className="col-lg-3">
                      <div className="digitCircle">4</div>
                      <p>If You Win, We Will Contact You By Phone And Email, So Look Out For Our Call!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="all-raffle">
          <div className="container">
            <div className="d-lg-flex align-items-center text-center justify-content-between mb-4">
              <div> <h2 className='title'>Raffles</h2></div>
              {/* <div>
                <ul className="filter">
                  <li><a href="#" className='filterActive'>All</a></li>
                  <li><a href="#">Cash</a></li>
                  <li><a href="#">Tech</a></li>
                  <li><a href="#">Holidays</a></li>
                  <li><a href="#">Other</a></li>
                </ul>

              </div> */}
            </div>
            <div className="row mx-0">
              {card}
            </div>
          </div>
        </section>

        <div className="bg-white py-4">
          <marquee>
            <a href="#" className='marqui-img'>
              <img src="assets/1.png" className='' alt="" />
            </a>
            {/* <a href="#" className='marqui-img'>
       <img src="assets/2.png" className='' alt="" />
       </a>
       <a href="#" className='marqui-img'>
       <img src="assets/3.png" className='' alt="" />
       </a>
       <a href="#" className='marqui-img'>
       <img src="assets/4.png" className='' alt="" />
       </a>
       <a href="#" className='marqui-img'>
       <img src="assets/5.png" className='' alt="" />
       </a>
       <a href="#" className='marqui-img'>
       <img src="assets/6.png" className='' alt="" />
       </a>
       <a href="#" className='marqui-img'>
       <img src="assets/7.png" className='' alt="" />
       </a>
       <a href="#" className='marqui-img'>
       <img src="assets/8.png" className='' alt="" />
       </a>
       <a href="#" className='marqui-img'>
       <img src="assets/9.png" className='' alt="" />
       </a> */}
          </marquee>
        </div>
      </div>
    </>
  )
}

export default Home