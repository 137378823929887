import React from 'react';
import './WinnersGallery.css';
import img from '../../assets/1000-X2.png';
import img1 from '../../assets/2000-X2.png';
import img2 from '../../assets/300- X2.png';
import img3 from '../../assets/5000-X2.png';
import img4 from '../../assets/10000-X2.png';

const WinnersGallery = () => {

  let galleryImgs = [img, img1, img2, img3, img4]

  let Winners_Gallery = []

  for(let i = 0; i < 14; i++){
    Winners_Gallery.push(
      <>
      <div className="col-lg-3 col-md-6 mb-3">
        <div className="galleryCard">
          <div className="gallery-img">
            <img src={galleryImgs[i]} alt="" />
          </div>
          <div className="overLay">
            <div className="overlay-withtext">
              <h2>$5,000 bank booster </h2>
              <p className='fs-14 textWarn text-uppercase'>Won by</p>
              <small className='textWarn text-uppercase'>Winning ticket</small>
              <div className="ticket-with-text mt-3">
                <h2 className='fs-16'>452</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }

  return (
    <>
      <div className="winnersGallery">
        <div className="container">
          <div className="d-flex align-items-center mb-3">
            <div className='me-2'>
              <h1 className="fw-700"><span class="iconify" data-icon="noto:trophy"></span></h1>
            </div>
            <h1 className="fw-700"> Winners</h1>
          </div>

          <div className="row mx-0">
            {Winners_Gallery}
          </div>
        </div>
      </div>
    </>
  )
}

export default WinnersGallery
