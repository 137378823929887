import React, { useState } from 'react';
import './Cart.css';
import { useNavigate } from "react-router-dom";
import cartImg from '../../assets/taxFree.webp'

const Cart = () => {

    const [isEmpty, setisEmpty] = useState(false);
    let navigate = useNavigate();

    return (
        <>
            <div className="Cart">
                {isEmpty ?
                    <>
                        <div className="emptyCart">
                            <div className="title">Empty Cart</div>

                            <div className="fs-16 fw-400 mb-3">Add items to your cart</div>

                            <div>
                                <button type='button' onClick={() => navigate('/')} className='btn btnWarn animated-button victoria-two '>
                                    GO BACK
                                </button>
                            </div>

                        </div>
                    </>
                    :
                    <>
                        <div className="NotEmpty">
                            <div className="cartSummary">
                                <div className="fs-36 fw-800">Cart Summary</div>
                            </div>

                            {/* <div className="countDownTimer py-2 mb-3">
                                <div className="row mx-0 justify-content-center">

                                    <div className="col-md-4 ">
                                        <div className="d-flex justify-content-center textDark text-center align-items-center">
                                            <div className="">
                                                <span class="iconify" data-icon="clarity:alarm-off-line"></span>
                                            </div>
                                            <div className="fs-16 fw-400 mx-2">
                                                Your cart will expire in
                                            </div>
                                            <div className="time fs-16 fw-400">0- : 0- : 7 : 56</div>
                                        </div>
                                    </div>
                                </div>


                            </div> */}

                            <div className="container mt-5">
                                <div className="row mx-0">
                                    <div className="col-lg-8">
                                        <div className="row mx-0 bgrow d-none d-lg-flex">
                                            <div className="col-lg-8">
                                                <div className="fs-20 fw-800">Competition</div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="fs-20 fw-800">Subtotal</div>

                                            </div>
                                        </div>
                                        <div className="row mx-0 align-items-center">

                                            {/* large screen cart */}
                                            <div className="col-lg-8 d-none d-lg-block">
                                                <div className="d-flex mt-3">
                                                    <div className='pointer'>
                                                        <span class="iconify fs-28" data-icon="charm:cross"></span>
                                                    </div>
                                                    <div className="product-img">
                                                        <img src={cartImg} alt="" />
                                                    </div>
                                                    <div className="fs-26 fw-600 text-capitalize">$7,000 Tax Free Cash</div>
                                                </div>
                                                <div className="d-flex mt-4 ms-4">
                                                    <div className="warnPill mx-1">
                                                        <span class="iconify" data-icon="charm:cross"></span>
                                                        <span className="d-inline-block ms-1">
                                                            2347
                                                        </span>
                                                    </div>
                                                    <button type='button' className='btn btnAdd'>
                                                        <span class="iconify" data-icon="akar-icons:plus"></span>
                                                        <span className="d-inline-block ms-1">Add</span>
                                                    </button>
                                                </div>
                                            </div>

                                            {/* small screen cart */}

                                            <div className="col-lg-8 bgDef d-block d-lg-none">
                                                <div className="d-flex mt-3">
                                                    <div className='pointer'>
                                                        <span class="iconify fs-28" data-icon="charm:cross"></span>
                                                    </div>
                                                    <div className="product-img">
                                                        <img src={cartImg} alt="" />
                                                    </div>
                                                    <div className="d-flex flex-column h-80 justify-content-between">

                                                        <div className="fs-16 fw-600 text-capitalize">$7,000 Tax Free Cash</div>

                                                        <div className="textWarn fs-26 fw-700">$4.99</div>


                                                        <div className="d-flex align-items-center justify-content-between">

                                                            <div className="fs-16 fw-400">1 Entries</div>
                                                            <div className="textWarn fs-16 fw-500">$4.99</div>

                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="px-4 mt-4" >
                                                    <div class="accordion bg-transparent borderTop" id="accordionExample">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="headingtwo">
                                                                <button class="accordion-button px-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapsetwo" aria-expanded="true" aria-controls="collapsetwo">
                                                                    Show Entries
                                                                </button>
                                                            </h2>
                                                            <div id="collapsetwo" class="accordion-collapse collapse show" aria-labelledby="headingtwo" data-bs-parent="#accordionExample">
                                                                <div class="accordion-body px-0 bg-transparent">
                                                                    <div className="d-flex">
                                                                        <div className="warnPill mx-1">
                                                                            <span class="iconify" data-icon="charm:cross"></span>
                                                                            <span className="d-inline-block ms-1">
                                                                                2347
                                                                            </span>
                                                                        </div>
                                                                        <button type='button' className='btn btnAdd'>
                                                                            <span class="iconify" data-icon="akar-icons:plus"></span>
                                                                            <span className="d-inline-block ms-1">Add</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="d-none d-lg-flex mt-4 ms-4">
                                                    <div className="warnPill mx-1">
                                                        <span class="iconify" data-icon="charm:cross"></span>
                                                        <span className="d-inline-block ms-1">
                                                            2347
                                                        </span>
                                                    </div>
                                                    <button type='button' className='btn btnAdd'>
                                                        <span class="iconify" data-icon="akar-icons:plus"></span>
                                                        <span className="d-inline-block ms-1">Add</span>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 d-none d-lg-block">
                                                <div className="totalPrice fs-16 fw-500">$4.99</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="cartTotal">
                                            <h3 className="fw-800 text-center fs-30">Cart total</h3>

                                            <div class="accordion bg-transparent" id="accordionExample">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id="headingOne">
                                                        <button class="accordion-button px-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                            Promo code
                                                        </button>
                                                    </h2>
                                                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                        <div class="accordion-body px-0 bg-transparent">
                                                            <div className="row mx-0">
                                                                <div className="col-6 ps-0">
                                                                    <input type="text" placeholder='enter promo code' className="form-control" />
                                                                </div>
                                                                <div className="col-6 pe-0">
                                                                    <button type='button' className='btn btnWarn animated-button victoria-two w-100'>Apply</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="d-flex justify-content-between align-items-center mt-5 mb-2">
                                                <div className="fs-26 fw-600">TOTAL</div>
                                                <div className="fs-26 fw-600">$1.99</div>
                                            </div>
                                            <button type='button' className='btn btnWarn animated-button victoria-two w-100'>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div>CHECKOUT</div>
                                                    <div>$1.99</div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </>
                }
            </div>

        </>
    )
}

export default Cart
