import React from 'react';
import './PersonalAccount.css';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

const PersonalAccount = () => {
  return (
    <>
      <Wrapper>
        <Container>
          <h1 className='fw-400 mb-4'>User Information</h1>

          <Row>
            <Col md={12} className="mb-3">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <div className="fs-16 fw-700">Date of birth</div>
                <Red>You must enter a valid date of birth.</Red>
              </div>
              <input type="text" className='form-control' placeholder='mm/dd/yyyy' />
            </Col>
            <Col md={12} className="mb-3">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <div className="fs-16 fw-700">Email address</div>
              </div>
              <input type="text" className='form-control' placeholder='Email' />
            </Col>
            <Col md={12} className="mb-3">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <div className="fs-16 fw-700">Phone number</div>
                <Red>Your contact number is missing, please add it as we will need to contact you if you win!</Red>
              </div>
              <Row className='mx-0'>
                <Col xs="2" lg="2" className='ps-0 pe-1'>
                  <input type="text" className='form-control' placeholder='' />
                </Col>
                <Col xs="10" lg="10" className='ps-1 pe-0'>
                  <input type="text" className='form-control' placeholder='Phone (we call our winners!)' />
                </Col>
              </Row>
            </Col>
          </Row>

          <Link to="/reset-password">Change your password</Link>

          <Row className="my-3">
            <Col lg="3">
              <div className="fs-16 fw-700 mb-3">Gender</div>
              <Form.Select aria-label="Default select example">
                <option>---------</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
                <option value="notsay">Prefer Not Say</option>
              </Form.Select>
            </Col>
            <Col lg="3">
              <div className="fs-16 fw-700 mb-3">Region</div>
              <Form.Select aria-label="Default select example">
                <option>---------</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
                <option value="notsay">Prefer Not Say</option>
              </Form.Select>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Accordion>
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Interests
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        <div className="fw-500 fs-16 mb-3">Interests (select all that apply)</div>
                       <Row>
                        <Col lg="4">
                        <INPUT>
                          <input type="checkbox" name="" id="" />
                          <label>Audio & Speakers</label>
                        </INPUT>
                        </Col>
                        <Col lg="4">
                        <INPUT>
                          <input type="checkbox" name="" id="" />
                          <label>Computers & Laptops</label>
                        </INPUT>
                        </Col>
                        <Col lg="4">
                        <INPUT>
                          <input type="checkbox" name="" id="" />
                          <label>Kids Toys</label>
                        </INPUT>
                        </Col>
                       </Row>

                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Competition Type
                      </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                      <div className="fw-500 fs-16 mb-3">Competition Type (select all that apply)</div>
                       <Row>
                        <Col lg="4">
                        <INPUT>
                          <input type="checkbox" name="" id="" />
                          <label> Big Raffles (Cars, £50k cash, £10k cash, Rolex etc)</label>
                        </INPUT>
                        <INPUT>
                          <input type="checkbox" name="" id="" />
                          <label> Mid-Size Raffles</label>
                        </INPUT>
                        <INPUT>
                          <input type="checkbox" name="" id="" />
                          <label> Small Raffles</label>
                        </INPUT>
                        </Col>
                       </Row>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Odds Preferences
                      </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <div className="fw-500 mb-3 fs-16">Odds Preferences (select all that apply)</div>
                       <Row>
                        <Col lg="4">
                        <INPUT>
                          <input type="checkbox" name="" id="" />
                          <label> Higher Price, less Numbers (10 tickets entry, 499 entries)</label>
                        </INPUT>
                        <INPUT>
                          <input type="checkbox" name="" id="" />
                          <label>  Medium price and numbers (5 tickets entry, 999 entries)</label>
                        </INPUT>
                        <INPUT>
                          <input type="checkbox" name="" id="" />
                          <label> Lower price, more numbers (2 tickets entry, 10,000 entries)</label>
                        </INPUT>
                        </Col>
                       </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion>
            </Col>
          </Row>
          <h6 className="mt-4 mb-2 fw-700">Contact preferences</h6>
          <INPUT>
            <input type="checkbox" name="" id="" />
            <label>I would like to receive exciting updates on raffles, my chosen charity, partner promotions, exclusive discounts and free tickets!</label>
          </INPUT>

          <Button className='btn btnWarn animated-button victoria-two'>Save Changes</Button>


        </Container>

      </Wrapper>

    </>
  )
}

export default PersonalAccount


const Wrapper = styled.section`
padding: 140px 0;


`

const Red = styled.div`
color: red;
font-size: 16px;
`
const Accordion = styled.div`
.accordion-item{
  border: 0;
  background-color: #28293D;
}
.accordion-button{
  background-color: #28293D;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  &::after{
    filter: invert(99%) sepia(16%) saturate(2%) hue-rotate(264deg) brightness(119%) contrast(100%);
  }
  &:focus{
    box-shadow: none;
   border: 0;
  }
}
`

const INPUT = styled.div`
display: flex;
align-items: baseline;
input{
  accent-color: var(--Warn);
  margin-right: 10px;
}
`

const Button = styled.a`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 24px;
  margin-top: 30px;
  width: 100%;
  color: #fff;
`