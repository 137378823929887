import React from 'react';
import './RaffleDetail.css';
import taxFree from '../../assets/taxFree.webp';
import RaffleCard from '../RaffleCard/RaffleCard';
import { Link } from 'react-router-dom';
import img from '../../assets/1000-X2.png';
import img1 from '../../assets/2000-X2.png';
import img2 from '../../assets/300- X2.png';
import img3 from '../../assets/5000-X2.png';
import img4 from '../../assets/8000-X2.png';

const RaffleDetail = () => {

  let cardImgs = [ {img: img, text: 1000}, {img: img1, text: 2000}, {img: img2, text: 3000}, {img: img3, text: 5000}, {img: img4, text: 8000}]


  const card = []

  for (var i = 0; i < 5; i++) {
    card.push(
      <>
        <div className="col-md-4 mb-3">
          <Link to="raffle-detail">
            <RaffleCard cardImgs={cardImgs[i]} />
          </Link>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="raffleDetail">
        <div className="container">
          <div className="row mx-0">
            <div className="col-lg-8">
              <div className="bannerView">
                <img src={taxFree} alt="" />
              </div>

              <div className="borderTop mt-5 mb-4 d-none d-md-block"></div>

              <div className="d-block d-md-none">
                <div className="fs-50 fw-800 mt-4">$750 Cash</div>
                <div className="textWarn text-center fs-36 mb-4">£0.89</div>

                <div className="row mx-0">
                  <div className="col-md-4 col-4 ps-0">
                    <div className="detailCard">
                      <div className="title">ENTRY PRICE</div>
                      <div className="price">$0.89</div>
                    </div>
                  </div>
                  <div className="col-md-4 col-4 px-0">
                    <div className="detailCard">
                      <div className="title">TOTAL TICKETS</div>
                      <div className="price">1499</div>
                    </div>
                  </div>
                  <div className="col-md-4 col-4 pe-0">
                    <div className="detailCard">
                      <div className="title">INSTANT DRAW</div>
                      <div className="price">18 Jul</div>
                    </div>
                  </div>
                </div>
                <div className="text-center fs-16 mt-3">See <a href="#">terms</a> for free postal entry*</div>

                <div className="detailProgress">
                  <div class="progress">
                    <div class="progress-bar" role="progressbar" aria-valuenow="42" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center fs-10">
                    <div>0</div>
                    <div>864 tickets left</div>
                    <div>1499</div>
                  </div>
                  <div className="progressTooltip">
                    42%
                    <div className="position-relative">
                      <div className="arrow"></div>
                    </div>
                  </div>
                </div>


                <div className="borderTop my-4"></div>

                <p>Instant draw Monday 18th Jul at 10PM via Random.org, an independent true random software.</p>

                <p>This competition will close early if all tickets sell out and the draw will take place on that same day!</p>
              </div>

              <h6>ABOUT THE PRIZE</h6>
              <p>Win $750 Cash for just</p>
              <p>There is $750 up for grabs!</p>
              <p>That could be the extra cash you need to fuel your bank holiday weekend!</p>
              <p>Enter now to secure your chance to win!</p>

              <h6>DETAILS</h6>
              <div className="row mx-0">
                <div className="col-6 px-0">
                  <ul>
                    <li>
                      £750 Cash
                    </li>
                    <li>
                      Tax Free
                    </li>
                  </ul>
                </div>
                <div className="col-6 px-0">
                  <ul>
                    <li>
                      Direct transfer to your bank account
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 d-none d-md-block">
              <div className="fs-50 fw-800 mb-3">$750 Cash</div>
              <div className="row mx-0">
                <div className="col-md-4 ps-0">
                  <div className="detailCard">
                    <div className="title">ENTRY PRICE</div>
                    <div className="price">$0.89</div>
                  </div>
                </div>
                <div className="col-md-4 px-0">
                  <div className="detailCard">
                    <div className="title">TOTAL TICKETS</div>
                    <div className="price">1499</div>
                  </div>
                </div>
                <div className="col-md-4 pe-0">
                  <div className="detailCard">
                    <div className="title">INSTANT DRAW</div>
                    <div className="price">18 Jul</div>
                  </div>
                </div>
              </div>
              <div className="text-center fs-16 mt-3">See <a href="#">terms</a> for free postal entry*</div>
              <div className="textWarn text-center fs-36 mb-5">£0.89</div>

              <div className="detailProgress">
                <div class="progress">
                  <div class="progress-bar" role="progressbar" aria-valuenow="42" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div className="d-flex justify-content-between align-items-center fs-10">
                  <div>0</div>
                  <div>864 tickets left</div>
                  <div>1499</div>
                </div>
                <div className="progressTooltip">
                  42%
                  <div className="position-relative">
                    <div className="arrow"></div>
                  </div>
                </div>
              </div>

              <div className="row mx-0">
                <div className="col-md-5 ps-0">
                  <select class="form-select" aria-label="Default select example">
                    <option selected>Select</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div className="col-md-7 pe-0">
                  <a href="#" className='btn btnWarn animated-button victoria-two mb-1'>Enter</a>
                  <a href="#" className='btn btnWarn animated-button victoria-two'>Select Tickets</a>
                </div>
              </div>

              <div className="borderTop my-4"></div>

              <p>Instant draw Monday 18th Jul at 10PM via Random.org, an independent true random software.</p>

              <p>This competition will close early if all tickets sell out and the draw will take place on that same day!</p>
            </div>
          </div>

          <div className="mobEntry d-block d-md-none">

            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="pointer">
                <span class="iconify counter" data-icon="akar-icons:circle-minus"></span>
              </div>
              <div className="ticketContain">
                <span className='d-inline-block me-1'>1 </span>
                ticket
              </div>
              <div className="pointer counter">
                <span class="iconify" data-icon="akar-icons:circle-plus"></span>
              </div>
            </div>

            <div>
              <a href="#" className='btn btnWarn animated-button victoria-two'>ADD TO CART
                <span className="price"> $1.89 </span>
              </a>
            </div>
          </div>

          <h1 className='mt-5 ps-3 mb-4'>You may be interested in</h1>

          <div className="row mx-0">
            {
              card.map(() => {
                return (
                  <>
                    {card}
                  </>
                )
              })
            }
          </div>


        </div>
      </div>
    </>
  )
}

export default RaffleDetail