import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import Charity from './components/Charity/Charity';
import Login from './components/Login/Login';
import Signup from './components/Signup/Signup';
import RaffleCard from './components/RaffleCard/RaffleCard';
import RaffleDetail from './components/RaffleDetail/RaffleDetail';
import ResetPassword from './components/ResetPassword/ResetPassword';
import RaffleList from './components/RaffleList/RaffleList';
import Cart from './components/Cart/Cart';
import Raffles from './components/Raffles/Raffles';
import Winners from './components/Winners/Winners';
import PersonalAccount from './components/PersonalAccount/PersonalAccount';
import WinnersGallery from './components/WinnersGallery/WinnersGallery';
import Faq from './components/Faq/Faq';
import Terms from './components/Terms/Terms';

function App() {
  return (
    <div>
      <Router>
        <Header/>
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/login" element={<Login />}/>
          <Route path="/signup" element={<Signup />}/>
          <Route path="/reset-password" element={<ResetPassword />}/>
          <Route path="/charities" element={<Charity/>}/>
          <Route path="/raffle-detail" element={<RaffleDetail />}/>
          <Route path="/raffle-list" element={<RaffleList />}/>
          <Route path="/cart" element={<Cart />}/>
          <Route path="/raffles" element={<Raffles />}/>
          <Route path="/winners" element={<Winners />}/>
          <Route path="/winners-gallery" element={<WinnersGallery />}/>
          <Route path="/faq" element={<Faq />}/>
          <Route path="/terms" element={<Terms />}/>
          <Route path="/personal-info" element={<PersonalAccount />}/>
        </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
