import React from 'react';
import './Footer.css';
import logo from '../../assets/logo.png';
import logo18 from '../../assets/logo-18plus.png';
import paymentMethod from '../../assets/paymentmethods.png';
import trustPilot from '../../assets/logo-trustpilot.svg';
import gameCare from '../../assets/logo-gamcare_half.png';
import beGamble from '../../assets/logo-be-gamble-aware.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <div className="Footer">
        <footer>
          <div className="container">
            <div className="row mx-0 justify-content-center align-items-center py-5">
              {/* <div className="col-lg-3 text-center">
                <img src={logo18} width={73} height={72} alt="" />
              </div> */}
              <div className="col-lg-3 text-center d-none">
                <img src={paymentMethod} className="w-100" height={50} w alt="" />
              </div>
              <div className="col-lg-3 text-center d-none">
                <a href="#" className='hoverBottom'>
                  <img src={trustPilot} alt="" />
                </a>
              </div>
              <div className="col-lg-3 text-center d-none">
                <a href="#"><img src={gameCare} width={187} alt="" /></a>
                <a href="#"> <img src={beGamble} width={187} alt="" /></a>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="borderWarn"></div>
          </div>

          <div className="subFooter">
            <div className="container">
              <div className="row mx-0">
                <div className="col-lg-4 text-center mb-5 mb-lg-0">
                  <a href="#">
                    <div className="footerLogo">
                      <img src={logo} alt="" />
                    </div>
                  </a>

                  <div class="social-icons">
                    <ul>
                      <li>
                        <a href="https://www.facebook.com/MillionaireRowRaffle/" target="_blank" rel="noopener noreferrer">
                          <span class="iconify icon" data-icon="dashicons:facebook-alt"></span>
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/RaffleRow" target="_blank" rel="noopener noreferrer"> 
                          <span class="iconify icon" data-icon="akar-icons:twitter-fill"></span>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/millionairerowraffle/" target="_blank" rel="noopener noreferrer">
                          <span class="iconify icon" data-icon="akar-icons:instagram-fill"></span>
                        </a>
                      </li>
                      <li>
                        <a href="https://discord.gg/Qd8KUPbrJT" target="_blank" rel="noopener noreferrer">
                          <span class="iconify icon" data-icon="akar-icons:discord-fill"></span>
                        </a>
                      </li>
                    </ul>
                  </div>

                </div>
                <div className="col-lg-8">
                  <div className="row mx-0">
                    <div className="col-lg-3 col-6 mb-4 mb-lg-0">
                      <h4>Website</h4>
                      <ul className='footerLinks'>
                        <li>
                          <Link to="/raffles" className='hoverBottom'>My Raffles</Link>
                        </li>
                        <li>
                          <Link to="/charities" className='hoverBottom'>My Charity</Link>
                        </li>
                        
                        <li>
                          <Link to="/winners-gallery" className='hoverBottom'>Winners Gallery</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-3 col-6 mb-4 mb-lg-0">
                      <h4>Info</h4>
                      <ul className='footerLinks'>
                        <li>
                          <Link to="/faq" className='hoverBottom'>Help</Link>
                        </li>
                        <li>
                          <Link to="/terms" className='hoverBottom'>Terms</Link>
                        </li>

                      </ul>
                    </div>
                    <div className="col-lg-3 col-6 d-none">
                      <h4>Categories</h4>
                      <ul className='footerLinks'>
                        <li>
                          <a href="#" className='hoverBottom'>Cash</a>
                        </li>
                        <li>
                          <a href="#" className='hoverBottom'>Tech</a>
                        </li>
                        <li>
                          <a href="#" className='hoverBottom'>Cars</a>
                        </li>
                        <li>
                          <a href="#" className='hoverBottom'>Holidays</a>
                        </li>
                        <li>
                          <a href="#" className='hoverBottom'>Indulgence</a>
                        </li>

                      </ul>
                    </div>
                    <div className="col-lg-3 col-6 ">
                      <h4>Contact</h4>
                      <ul className='footerLinks'>
                        <li className='d-none'>
                          <div>Unit 5, 10 Beechen Grove, Watford, WD17 2AD</div>
                        </li>
                        <li className='d-none'>
                          <a href="#" className='d-inline-flex align-items-center hoverBottom'>
                            <div className='me-2'><span class="iconify" data-icon="ant-design:phone-filled"></span></div>
                            <div>01923 918637</div>
                          </a>
                        </li>
                        <li>
                          <a href="https://discord.gg/Qd8KUPbrJT" target="_blank" rel="noopener noreferrer" className='d-inline-flex align-items-center hoverBottom'>
                            <div className='me-2'><span class="iconify" data-icon="akar-icons:discord-fill"></span></div>
                            <div>DISCORD</div>
                          </a>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center mt-5 pb-3 d-none">
                <div className="textWarn">
                  <div className="fs-14 mb-1">Copyright © 2019 - 2022 Millionaire Row Raffle Ltd.</div>
                  <div className="fs-14">All rights reserved. Company No. 10962686</div>
                </div>
              </div>
            </div>
          </div>




        </footer>
      </div>
    </>
  )
}

export default Footer