import React from 'react';
import { Link } from 'react-router-dom';
import './Signup.css';

const Signup = () => {
  return (
    <>
       <div className="Login">
        <div className="container">
          <div className="row mx-0">
            <div className="col-lg-3 ps-lg-0 d-none d-lg-block order-first">
              <div className="d-flex">
                <div className='me-2'><span class="iconify" data-icon="akar-icons:pencil"></span></div>
                <div className="d-flex flex-column">
                  <div className="fs-16 fw-700">Play Raffles from just token</div>
                  <p>
                    Millionaire Row Raffle tickets cost just token. Play the raffles for your chance to win incredible prizes!
                  </p>

                </div>
              </div>
              <div className="d-flex">
                <div className='me-2'><span class="iconify" data-icon="akar-icons:ticket"></span></div>
                <div className="d-flex flex-column">
                  <div className="fs-16 fw-700">Raising money for charity</div>
                  <p>
                    8% of every ticket you purchase will be donated directly to your chosen charity and 2% to all the other
                    charities!
                  </p>

                </div>
              </div>
              <div className="d-flex">
                <div className='me-2'><span class="iconify" data-icon="emojione-monotone:reminder-ribbon"></span></div>
                <div className="d-flex flex-column">
                  <div className="fs-16 fw-700">Sign up and pick your charity</div>
                  <p>
                    Charity is at the heart of Millionaire Row and the charity will be chosen by our community every month.
                  </p>

                </div>
              </div>
            </div>
            <div className="col-lg-7 offset-lg-1 order-lg-first order-last">
              <h2 className='title'>Join Millionaire Row</h2>
              <h4>Already a member <Link to="/login">Sign in</Link></h4>

              <div className="loginForm mt-4 mt-md-0">
                <div className="row mx-0 mb-3">
                  <div className="col-6 ps-0">
                    <label htmlFor="firstName" className='mb-2'>First Name</label>
                    <input type="text" placeholder='Your first name' className='form-control' />
                  </div>
                  <div className="col-6 pe-0">
                    <label htmlFor="lastName" className='mb-2'>Last Name</label>
                    <input type="text" placeholder='Your last name' className='form-control' />
                  </div>
                </div>
                <div className="row mx-0 mb-3">
                  <div className="col px-0">
                    <label htmlFor="emailAddress" className='mb-2'>Email address</label>
                    <input type="email" placeholder='Your email address' className='form-control' />
                  </div>
                </div>
                <div className="row mx-0 mb-3">
                  <div className="col-12 px-0">
                    <label className='mb-2'>Phone Number</label>
                  </div>
                  <div className="col-3 ps-0 pe-1">
                    <input type="text" className='form-control' />
                  </div>
                  <div className="col-9 ps-1 pe-0">
                    <input type="text" className='form-control' placeholder='Phone (we call our winners!)' />

                  </div>
                </div>
                <div className="row mx-0 mb-3">
                  <div className="col px-0">
                    <label htmlFor="choosePassword" className='mb-2'>Choose password</label>
                    <input type="password" className='form-control' placeholder='Enter a password' />
                  </div>
                </div>
                <div className="row mx-0 mb-3">
                  <div className="col px-0">
                    <label htmlFor="confirmPassword" className='mb-2'>Confirm password</label>
                    <input type="password" className='form-control' placeholder='Confirm your password' />
                  </div>
                </div>

                <small>
                  I would like to receive exciting updates on raffles, my chosen charity, partner promotions, exclusive discounts and free tickets!
                </small>

                <div class="my-4">
                  <input type="radio" id="test1" name="radio-group"  />
                  <label for="test1" className='me-4'>Yes</label>

                  <input type="radio" id="test2" name="radio-group"  />
                  <label for="test2">No</label>

                </div>

                <div className="row mx-0 mb-2">
                  <div className="col px-0">
                    <Link  to="/" className="btn btnWarn animated-button victoria-two d-flex justify-content-center">
                      CREATE ACCOUNT
                    </Link>
                  </div>
                </div>
                <div className="fs-10">
                  By creating an account you agree that you are at least 18 years of age, a and have read, accept and agree to the <a href="#" className="textWarn">Terms and Conditions</a> and <a href="#" className="textWarn">Privacy Policy</a>.
                </div>
              </div>
            </div>
            <div className="col-lg-2 order-lg-last order-first pe-lg-0 mb-3 d-none">
              <div className="fs-24 fw-700 text-center">Or sign up via</div>
              <div class="social-icons">
                    <ul>
                      <li>
                        <a href="#">
                          <span class="iconify icon" data-icon="dashicons:facebook-alt"></span>
                        </a>
                      </li>
                      {/* <li>
                        <a href="#">
                          <span class="iconify icon" data-icon="akar-icons:twitter-fill"></span>
                        </a>
                      </li> */}
                      {/* <li>
                        <a href="#">
                          <span class="iconify icon" data-icon="akar-icons:instagram-fill"></span>
                        </a>
                      </li> */}
                      <li>
                        <a href="#">
                        <span class="iconify icon" data-icon="akar-icons:google-fill"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Signup