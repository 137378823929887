import React from 'react';
import { Link } from 'react-router-dom';
import './Winners.css';
import img from '../../assets/winner-img.webp';

const Winners = () => {

  let winners = []

  for(let i = 0; i < 10; i ++){
    winners.push(
      <>
      <div className="winnerCard">
        <div className="winner-img">
          <img src={img} alt="" />
        </div>
        <div className="d-flex flex-column">
        <div className="fs-20 fw-600">Venetian Dream Holiday</div>
        <p className="fs-16 fw-400">Congratulations David Thompson with winning ticket 1314</p>
        </div>
      </div>
      </>
    )
  }

  return (
    <>
      <div className="Winners">
        <div className="bgWinner py-4">
          <div className="container">
            <div className="d-flex align-items-center">
              <Link to="/" className='text-white'>
                <span class="iconify fw-900 fs-24" data-icon="akar-icons:chevron-left">
                </span>
              </Link>
              <h1 className="text-center fw-700 mb-0 flex-1">Millionaire Row Winners</h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="searchContainer">
            <div className="position-relative">
              <input type="text" placeholder='Search Prize byn name' />
              <span class="iconify" data-icon="akar-icons:search"></span>
            </div>
          </div>
        </div>

        <section>
          <div className="container">
            <h3 className='text-center'>July 2022</h3>
          <div className="WinnersThisMonth p-4">
            {winners}
          </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Winners
