import React from 'react';
import './RaffleCard.css';
import raffle from '../../assets/taxFree.webp';

const RaffleCard = ({cardImgs}) => {
  return (
    <>
      <div className="raffleCard">
        <div className="raffle-img">
          {/* <img src={raffle} className="d-none d-md-block" alt="" /> */}
          <img src={cardImgs.img} className="" alt="" />
          {/* <div className="timer pulse">
            <div className="timeHead">DRAW IN</div>
            <div className="timeBody">
              <div className="row mx-0 px-2">
                <div className="col px-1">07</div>
                <div className="col px-1">:</div>
                <div className="col px-1">03</div>
                <div className="col px-1">:</div>
                <div className="col px-1">09</div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="raffleBody">
          <h2 className='fs-24 text-white'>$ {cardImgs.text}</h2>
          <h6 className='textWarn fs-20 fw-800 mb-3'>4 Token</h6>

          <div class="progress">
            <div class="progress-bar w-75" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
          </div>

          <div className="d-flex justify-content-between mt-2 cardPannel pb-5 pb-md-0">
            <div className='d-flex align-items-center'>
              <span class="iconify" data-icon="ant-design:thunderbolt-filled"></span>
              <div>Instant draw</div>
            </div>
            <div className='d-flex-column'>
              <div className="d-flex align-items-center">
                <span class="iconify" data-icon="entypo:ticket"></span>
                <div>227 / 399</div>
              </div>
              <div className="d-flex align-items-center mt-2">
                <span class="iconify" data-icon="akar-icons:clock"></span>
                <div><span class="iconify" data-icon="fa-solid:less-than"></span> 1d</div>
              </div>

            </div>
          </div>
          <div className="d-block d-md-none">
          <a href="#" className='btn btnWarn animated-button victoria-two'>Enter Now</a>
          </div>
        </div>
      </div>

    </>
  )
}

export default RaffleCard