import React from 'react';
import { Link } from 'react-router-dom';
import './Login.css';

const Login = () => {
  return (
    <>
    


      <div className="Signup pb-5 pb-md-0">
        <div className="container">
          <div className="row justify-content-center align-items-baseline mx-0">
            <div className="col-lg-9">
              <div className="row justify-content-center align-items-center mx-0">
                <div className="col-lg-5 ">
                  <div className="signUp-form">
                    <div className="title">Sign in</div>
                    <h4 className='mb-3'>Not a member? <Link to="/signup" className="textWarn">Sign up</Link></h4>

                    <div className="form-row">
                      <div className="col">
                        <input type="email" placeholder='Email' className='form-control' />
                      </div>
                    </div>
                    <div className="form-row my-3">
                      <div className="col">
                        <input type="password" placeholder='Password' className='form-control' />
                      </div>
                    </div>
                    <div className="form-row mb-3">
                      <div className="col">
                       <a href="#" className='btn btnWarn animated-button victoria-two d-flex justify-content-center'>SIGN IN</a>
                      </div>
                    </div>
                    <small>Forgotten your password? 
                      <Link to="/reset-password" className="textWarn"> Reset Password</Link></small>

                  </div>
                </div>
                <div className="col-lg-5 offset-lg-1 d-none">
                  <div className="fs-24 fw-700 text-center">Or sign in via</div>
                  <div class="social-icons">
                    <ul>
                      <li>
                        <a href="#">
                          <span class="iconify icon" data-icon="dashicons:facebook-alt"></span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <span class="iconify icon" data-icon="akar-icons:twitter-fill"></span>
                        </a>
                      </li>
                      {/* <li>
                        <a href="#">
                          <span class="iconify icon" data-icon="akar-icons:instagram-fill"></span>
                        </a>
                      </li> */}
                      <li>
                        <a href="#">
                        <span class="iconify icon" data-icon="akar-icons:google-fill"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </>
  )
}

export default Login