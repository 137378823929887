import React from 'react';
import { Link } from 'react-router-dom';
import './ResetPassword.css'

const ResetPassword = () => {
    return (
        <>
            <div className="resetPassword">
                <div className="container">
                    <h2 className='fs-24 fw-600 mb-4'>Change your password</h2>

                    <div className="fs-16 fw-400">
                        Please enter the email address associated with your account and we’ll send you a password reset email
                    </div>

                    <div className="row mx-0 my-3">
                        <div className="col-lg-3 col-md-4 px-0">
                            <input type="email" placeholder='Enter email' className='form-control' />
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4 px-0 mb-3">
                        <Link to="/login" className='btn btnWarn animated-button victoria-two'>Confirm</Link>
                    </div>

                    <div className="fs-16 fw-400">
                    Having problems resetting your password? Please join the live chat on the site or email us at support@raffolux.com for assistance.
                    </div>

                </div>
            </div>

        </>
    )
}

export default ResetPassword
