import React from 'react';
import './RaffleList.css';
import applePay from '../../assets/applepay-paypal.svg';
import tp from '../../assets/tp.svg';
import banner from '../../assets/mainBanner.png';
import bannerMob from '../../assets/mainBanner.png';
import RaffleCard from '../RaffleCard/RaffleCard';
import { Link } from 'react-router-dom';

const RaffleList = () => {

  const card = []


  for (var i = 0; i < 4; i++) {
    card.push(
      <>
        <div className="col-md-4 mb-3">
          <Link to="raffle-detail">
            <RaffleCard />
          </Link>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="raffleList">
        <div className="topBanner d-none">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <img src={applePay} className='applePay' alt="" />
              </div>
              <div>
                <img src={tp} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="bannerImg">
          <img src={banner} className="w-100 d-none d-lg-block" alt="" />
          <img src={bannerMob} className="w-100 d-block d-lg-none" alt="" />
        </div>
        <div className="container">
          <h1 className='mb-4'>Cash Raffles</h1>

          <p>
            Imagine waking up with an extra few hundred or thousand pounds in your pocket...well, with Millionaire Row Raffle you can make that dream a reality. We host multiple cash raffles every month so there’s always a cash prize up for grabs. Ranging from 1-10 ticket entries depending on the prize amount. The best thing about a cash raffle is that you can spend your winnings on whatever you like! Deposit for a house? Savings? New car? Shopping spree? You name it and it’s yours.
          </p>
          <p>
            Our cash raffles are all tax-free and the money is sent straight to your bank account, it really is that simple. From bank top-ups of £250 to our whopping jackpot prize of £50,000, winning a Millionaire Row Raffle cash raffle can be truly life changing. The best part is, whilst entering our cash competitions in the hopes of winning money for yourself and your family, you’re also donating to charity.
          </p>
          <p>
            Take a look at the range of cash raffle prizes you can enter at Millionaire Row Raffle today and who knows? Today could be your lucky day!
          </p>



          <section className='my-5'>
            <div className="container">
              <div className="row mx-0">
                <div className="col-lg-4 ps-lg-0 mb-3 mb-lg-0">
                  <div className="statCard">
                    <h2>$6.4m</h2>
                    <h6>won in prizes</h6>
                  </div>
                </div>
                <div className="col-lg-4 mb-3 mb-lg-0">
                  <div className="statCard">
                    <h2>$501k</h2>
                    <h6>raised for charity</h6>
                  </div>
                </div>
                <div className="col-lg-4 pe-lg-0">
                  <div className="statCard">
                    <h2>7,345</h2>
                    <h6>raffle winners</h6>
                  </div>
                </div>
              </div>
            </div>
          </section>


          <div className="row mx-0">
            {
              card.map(() => {
                return (
                  <>
                    {card}
                  </>
                )
              })
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default RaffleList