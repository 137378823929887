import React from 'react';
import { Link } from 'react-router-dom'
import './Raffles.css';

function Raffles() {
  return (
    <>
        <div className="Raffles">
          <div className="container">
            <h1 className="fw-700">My Raffles</h1>
            <div className="fs-16 fw-500">You currently have no tickets in any active raffles. <Link to="/">Click here to see what's on.</Link></div>
            <div className="row mx-0">
              <div className="col-lg-6 text-center">
                <div className="raffleTab">
                  Active raffles
                </div>
                <div className="fs-16 fw-400 mb-4">You are not currently entered into any active raffles.</div>
                <Link to="/">Click here to see what's on.</Link>

              </div>
              <div className="col-lg-6 text-center">
                <div className="raffleTab">
                Recently ended raffles
                </div>
                <div className="fs-16 fw-400 mb-4">You haven't entered any raffles yet..</div>
                <Link to="/">Click here to see what's on.</Link>

              </div>
            </div>
          </div>
        </div>
      
    </>
  )
}

export default Raffles
