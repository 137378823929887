import {
    JSONClient
} from "./bootstrap";
import MillionareRowException from "./MillionareRowException";

export const generateAndGetUser = async (data) => {
    try {
        const res = await JSONClient.post('/users/create-account', data);
        return res.data
    } catch (e) {
        throw new MillionareRowException(e.response.data, e.response.status);
    }
}

export const submitSignature = async (data) => {
    try {
        const res = await JSONClient.post('/users/generate-device-token', data);
        return res.data;
    } catch (e) {
        throw new MillionareRowException(e.response.data, e.response.status);
    }
}

export const logoutUser = async (token) => {
    try {
        const res = await JSONClient.post('/users/logout', {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return res.data
    } catch (e) {
        throw new MillionareRowException(e.response.data, e.response.status);
    }
}