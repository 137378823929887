import {
    createStore
} from 'redux'
import {
    persistStore,
    persistReducer
} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
const initialState = {

    userStore: {
        user: {
            id: null,
            name: null,
            email: null,
            publicAddress: null,
            dateOfBirth: null,
            nonce: null,
            gender: null
        },
        token: null
    }
}


const reducer = (state, {
    type,
    payload
}) => {
    switch (type) {
        case 'SAVE_USER':
            return {
                ...state,
                userStore: payload
            }
            default:
                return {
                    ...state
                }
    }
}

const persistConfig = {
    key: 'millrow',
    storage,
}

const persistedReducer = persistReducer(persistConfig, reducer)


const store = createStore(persistedReducer, initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

// eslint-disable-next-line
const persistedStore = persistStore(store);
export default store