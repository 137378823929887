import React, { useState } from 'react';
import './Charity.css';
import logoImg from '../../assets/actionaid.png'
import Modal from 'react-bootstrap/Modal';

const Charity = () => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const card = []

  for (var i = 0; i < 0; i++) {
    card.push(
      <>
        <div className="col-lg-3 mb-3">
          <div className="charityPartner" onClick={handleShow}>
            <div className="d-flex align-items-center">
              <div className="logo-img">
                <img src={logoImg} alt="" />
              </div>

              <div className="d-flex flex-column mx-lg-auto">
                <div className='fs-10 fw-400 textGray'>DONATIONS</div>
                <div className="textWarn fs-20 fw-700">$2,837</div>
              </div>
            </div>

            <div className="textWarn fs-16 fw-400 mb-3">ActionAid UK</div>

            <p className="fs-14 fw-400 text-white line-clamp ">
              
            </p>

          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="Charity">
        <div className="container">
          <h2 className="raisedHeading">
            <span class="iconify textWarn" data-icon="emojione-monotone:reminder-ribbon"></span> Total raised for charity</h2>
          <h2 className="raisedAmmount">$ 0</h2>

          <div className="cardSection">
            <div className="row mx-0">
              {card}
            </div>

          </div>
        </div>
      </div>

      <div className="charityModal">
        <Modal show={show} onHide={handleClose} animation={true} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Body>
          <div className="innerModal">
          <div className="logo">
              <img src={logoImg} alt="" />
            </div>
            <div className="title"><a href="#">Alzheimer’s Society</a></div>
            <p className="desc">
              Alzheimer’s Society is the UK’s leading dementia charity, working tirelessly to create change, fund research and deliver and improve care and support.
            </p>
          </div>
          </Modal.Body>
        </Modal>
      </div>

    </>
  )
}

export default Charity